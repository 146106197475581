import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import( "../views/Home.vue"),
		meta: { requiresCodeAuth: false }
	},
	{
		path: '/connexion',
		name: 'connexion',
		component: () => import( '../views/Login.vue')
	},
	{
		path: '/tableau',
		name: 'export csv hub',
		component: () => import( '../views/ExportData.vue'),
		meta: { requiresConnection: true }
	},
	{
		path: '/live-data',
		name: 'Courbes Real time',
		component: () => import( '../views/CourbesRealTime.vue'),
		meta: { requiresConnection: false }
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: () => import( /*webpackChunkName: "courbe température" */ '../views/ResetPassword.vue')
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: () => import( /*webpackChunkName: "courbe température" */ '../views/ForgotPassword.vue')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import( '../views/Register.vue')
	},
	{
		path: '/home',
		name: 'tableau-de-bord',
		component: () => import( '../views/TableauDeBord.vue'),
		meta: { requiresConnection: true }
	},
	{
		path: "/account",
		name: "my account",
		component: () =>import("../views/Account.vue"),
		meta: { requiresConnection: true }
	},
	{
		path: '/admin/creation-activation',
		name: 'creation activation',
		component: () => import( '../views/CreationAttribution.vue'),
		meta: { requiresAdminAuth: true }
	},


	{
		path: '/admin/liste-utilisateurs',
		name: 'liste des utilisateurs',
		component: () => import( '../views/ListUsers.vue'),
		meta: { requiresAdminAuth: true }
	},
	{
		path: '/admin/liste-tags',
		name: 'liste des tags',
		component: () => import( '../views/ListTags.vue'),
		meta: { requiresAdminAuth: true }
	},
	{
		path: '/admin/register-token-creation',
		name: 'creation token',
		component: () => import( '../views/TokenRegisterCreation.vue'),
		meta: { requiresAdminAuth: true }
	},
	{
    path: "/biotag/medical",
    name: "biotag-medical",
    component: () => import( "../views/ImplantMedical.vue"),
	meta: { requiresCodeAuth: false }
  },
  {
    path: "/biotag/performance",
    name: "biotag-performance",
    component: () => import( "../views/ImplantPerformance.vue"),
	meta: { requiresCodeAuth: false }
  },
  {
    path: "/biotag/plateforme",
    name: "biotag-plateforme",
    component: () => import( "../views/ImplantPlateforme.vue"),
	meta: { requiresCodeAuth: false }
  },
  {
    path: "/logiciel/health-cloud",
    name: "Biotag Cloud",
    component: () => import( "../views/HealthCloud.vue"),
	meta: { requiresCodeAuth: false }
  },
  {
    path: "/boitier/convergence",
    name: "boitier-convergence",
    component: () => import( "../views/BoitierConvergence.vue"),
	meta: { requiresCodeAuth: false }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import( "../views/Contact.vue"),
	meta: { requiresCodeAuth: false }
  },
  {
    path: "/about",
    name: "About",
    component: () => import( "../views/About.vue"),
	meta: { requiresCodeAuth: false }
  },
  {
    path: "/code-access",
    name: "code-access",
    component: () => import("../views/CodeForm.vue"),
  },
]



function getRESTApiUri()
    {
        let uri
        if (window.location.hostname == '192.168.1.51'){
            uri = window.location.protocol + "//" + window.location.hostname + ":3000/api/v1";
        }else{
            uri = window.location.protocol + "//" + window.location.hostname + "/api/v1";
        }
    return uri;
};

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) { 
		return { x: 0, y: 0 }
	}
})


router.beforeEach(async (to, from, next) => {
    let isAuthenticated = await testToken();
    let admin = adminTest();
    let isCodeAuthenticated = await testCode();
    const testCode1 = test(isCodeAuthenticated);
    if (to.meta.requiresConnection && isAuthenticated === false) {
        next({ name: 'connexion' });
    }
    if (to.meta.requiresCodeAuth && !testCode1) {
        next({ name: 'code-access' });
    } else if (to.meta.requiresAdminAuth && admin == false) {
        next({ name: 'user-interface' });
    } 
     else {
        next();
    }
});

async function testToken(){
	if (localStorage.getItem('accessToken') !== null){
			const token = localStorage.getItem('accessToken');
			const url = getRESTApiUri() + `/me/${token}`;
			return fetch(url)
				.then(res => res.text())
				.then((result) => {
					const response = JSON.parse(result);
					if(response.accessPage == true){
						return true;
					}
				})
				.catch((error) => {
					console.log(error)
					return switchToken();
				});
	}else{
		return false
	}
};

async function testCode() {
  // Vérifier si l'item 'code' existe dans le localStorage
  if (localStorage.getItem('code') !== null) {
    const code = localStorage.getItem('code');
    const url = getRESTApiUri() + `/check-code-existence/${code}`;
    return fetch(url)
		.then(res => res.text())
		.then((result) => {
        	const response = JSON.parse(result);
			if(response.length > 0){
				return true;
			}
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    // Si l'item 'code' n'existe pas, le créer avec une valeur par défaut
    return false;
  }
};

async function switchToken(){
	const refreshToken = localStorage.getItem('refreshToken');
	const url = getRESTApiUri() + `/refresh-token`;
	return fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				refreshToken : refreshToken,
		})
	})
	.then(res => res.text())
	.then((result) => {
		const data = JSON.parse(result);
		localStorage.setItem('accessToken', data.accessToken)
		return true;
	});	
}

function adminTest(){
	const user = JSON.parse(localStorage.getItem('user'))
	const AccessAdmin = JSON.parse(localStorage.getItem('accessAdmin'))
	if(user){
		if(user.admin == 'true' && AccessAdmin == true){
			return true;
		}else{
			return false;
		}
	}
}

function test(x) {
  	return String(x) == 'true';
}

export default router